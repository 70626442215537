import axios from '@axios'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    async create({ commit }, fd) {
      return await axios
        .post('employee-create-user//', fd)
    },
    async setPassword({ commit }, fd) {
      return await axios
        .post('password-set/', fd)
    },
  },
}
