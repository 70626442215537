import axios from "@axios";

export default function createQueueStoreModule(apiEndpoint) {
  return {
    namespaced: true,
    state: {
      apiData: null,
      isFetching: false,
      fetchQueue: [],
    },
    mutations: {
      SET_API_DATA(state, data) {
        state.apiData = data;
        state.isFetching = false;
        while (state.fetchQueue.length) {
          const callback = state.fetchQueue.shift();
          callback(data);
        }
      },
      ADD_TO_QUEUE(state, callback) {
        state.fetchQueue.push(callback);
      },
    },
    actions: {
      fetchData({ commit, state }) {
        // if (state.apiData) {
        //   return Promise.resolve(state.apiData);
        // } else if (!state.isFetching) {
        //   state.isFetching = true;
        //   return axios
        //     .get(apiEndpoint)
        //     .then((response) => {
        //       commit("SET_API_DATA", response.data);
        //       return response.data;
        //     })
        //     .catch((error) => {
        //       state.isFetching = false;
        //       throw error;
        //     });
        // } else {
        //   return new Promise((resolve) => {
        //     commit("ADD_TO_QUEUE", resolve);
        //   });
        // }
      },
    },
    getters: {
      getApiData: (state) => state.apiData,
    },
  };
}
