import { getOrSetStore } from "@/utils/helpers";

export default {
  namespaced: true,
  state: {
    attendanceCheck: {},
  },

  mutations: {
    SET_ATTENDANCE_CHECK(state, { data }) {
      state.attendanceCheck = data;
    },
  },
  actions: {
    getCheckStatus({ state, commit }) {
      return getOrSetStore(
        state,
        "attendanceCheck",
        "attendance/is-checked-in/",
        (newData) => {
          commit("SET_ATTENDANCE_CHECK", { data: newData });
        }
      ).then(() => {
        return state.attendanceCheck;
      });
    },
  },
};
