const representationStringToNumber = (rawString) => {
    return Number(rawString.replace(/,/g, ""))
}

const numberToRepresentationString = (rawNumber) => {
    return String(rawNumber.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export const isNegetiveRepresentationString = (a) => {
    return representationStringToNumber(a) < 0
}

export const addRepresentationStrings = (a, b) => {
    let number1 = representationStringToNumber(a)
    let number2 = representationStringToNumber(b)
    let numberResult = number1 + number2
    let representationStringResult = numberToRepresentationString(numberResult)
    return representationStringResult
}