import Vue from "vue";
import VueRouter from "vue-router";

import dashboard from "./routes/dashboard";
import { hasPermissions } from "@/utils/helpers";
import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "home" } },

    ...dashboard,
    // ...uiElements,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;

router.beforeEach(async (to, from, next) => {
  if (to.meta.model != from.meta.model) {
    store.state.app.filter = {};
  }

  if (!localStorage.getItem("access_token") && to.name !== "auth-login") {
    return next("/login");
  }

  // Ensure that user data is loaded before checking permissions
  if (localStorage.getItem("access_token")) {
    await store.dispatch("auth/getProfile");
  }

  const requiredPermissions = to.meta.permissions;

  // Check if the user has the required permissions
  if (requiredPermissions && !hasPermissions(requiredPermissions)) {
    // Redirect to a permission denied page or handle accordingly
    next({ name: "permission-denied" });
  } else {
    // Continue with the navigation
    next();
  }
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});
