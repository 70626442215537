import axios from "@axios";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    data: {},
    selected_attendance: {},
  },
  getters: {
    selected_attendance: state => state.selected_attendance,
  },

  mutations: {
    SET_ATTENDANCE_DATA(state, { data }) {
      state.data = data;
    },
    SET_SELECTED_ATTENDANCE(state, data) {
      state.selected_attendance = data;
    },
  },

  actions: {
    getAttendance({ commit }) {
      return axios.get("attendance/is-checked-in/").then((response) => {
        commit("SET_ATTENDANCE_DATA", { data: response.data });
        return response;
      });
    },
    attendaceCheck({ commit }, fd) {
      return axios
        .post("attendance-check/", fd)
        .then((response) => {
          return response;
        })
        .catch(() => {});
    },
  },
};
