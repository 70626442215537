import axios from "@axios";
import router from "@/router";
import { getOrSetStore } from "@/utils/helpers";
import { initializeSocketConnection } from "@/websockets/websockets"; // Adjust path as needed

export default {
  namespaced: true,
  state: {
    user: {},
    employee: {},
    userFromUserId: {},
  },

  mutations: {
    SET_USER_FROM_USER_ID(state, { data }) {
      state.userFromUserId = data;
    },
    SET_USER_LOGIN(state, { data }) {
      state.user = data;
    },
    SET_EMPLOYEE_RECORD(state, { data }) {
      state.employee = data;
    },
    SET_USER_DATA(state, { data }) {
      state.user = data;
    },
    RESET_STATE(state) {
      state.user = {};
      state.employee = {};
    },
  },
  actions: {
    async login({}, fd) {
      const response = await axios.post("/jwt-token/", fd);
      if (response.status == 200) {
        const newAccessToken = response.data.access;
        const newRefreshToken = response.data.refresh;
        localStorage.setItem("access_token", newAccessToken);
        localStorage.setItem("refresh_token", newRefreshToken);
      }
      return response;
    },

    isUserAdmin({ dispatch }) {
      return dispatch("getProfile")
        .then((userData) => {
          return (
            userData.permissions &&
            userData.permissions.includes("base.view_admin_dahsboard")
          );
        })
        .catch((err) => {
          return false;
        });
    },

    getProfile({ state, commit }) {
      if (!localStorage.getItem("access_token")) return;
      return getOrSetStore(state, "user", "/me/", (newData) => {
        commit("SET_USER_DATA", { data: newData });

        if (
          newData.permissions &&
          newData.permissions.includes("user.view_user")
        ) {
          return axios
            .get(`/user/${newData.user_id}/`)
            .then((response) => {
              commit("SET_USER_FROM_USER_ID", { data: response.data });
              // initializeSocketConnection(response.data);
              return response.data;
            })
            .catch((error) => {
              console.error("Error fetching user company data:", error);
              throw error;
            });
        }
      }).then(() => {
        return state.user;
      });
    },
    getEmployeeRecord({ commit }) {
      return axios
        .get("me-employee/ ")
        .then((response) => {
          commit("SET_EMPLOYEE_RECORD", { data: response.data });
          return response;
        })
        .catch(() => {
          router.push("/login");
        });
    },

    logout({ commit }) {
      commit("RESET_STATE");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("userData");
      // localStorage.removeItem('signature');
      router.push("/login");
    },
  },
  getters: {
    actionPermissons(state) {
      return state.user.permissions.map((str) => str.split(".").pop());
    },
    hasPermission: (state) => (permission) => {
      return state.user.permissions.includes(permission);
    },
    checkPermissions(state, getters) {
      return (action, model) => {
        if (getters.hasPermission("base.view_admin_dahsboard")) {
          return true;
        } else {
          let hasPermission = getters.actionPermissons.some((str) => {
            return str === `${action}_${model}`;
          });

          return hasPermission;
        }
      };
    },
  },
};
