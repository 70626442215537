import axios from '@axios'

export default {
  namespaced: true,
  state: {
    data: {
      results: [],
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },
    PUSH_DATA(state, data) {
      state.data.results.push(resp.data)
    },
    UPDATE_DATA(state, data) {
      state.data.results = state.data.results.map(it => (it.id != id ? it : data))
    },
    DELETE_DATA(state, id) {
      state.data.results = state.data.results.filter(it => it.id != id)
    },
  },
  getters: {
    GET_RESULTS(data) {
      return data.results
    },
  },
  actions: {
    async getAll({ commit }, { search, page }) {
      return await axios
      .get(`timeoff-request?search=${search ?? ''}&page=${page}`)
        // .get('department')

        .then(resp => {
          commit('SET_DATA', resp.data)
          return resp
        })
    },
    async getOne({ commit }, id) {
      return await axios
        .get(`timeoff-request/${id}/`)
        .then(resp => resp.data)
    },
    async create({ commit }, fd) {
      return await axios
        .post('timeoff-request/', fd)
        .then(resp => {
          commit('PUSH_DATA', resp.data)
          return resp
        })
    },
    async update({ commit }, { id, fd }) {
      return await axios
        .patch(`timeoff-request/${id}/`, fd)
        .then(resp => {
          commit('UPDATE_DATA', resp.data)
          return resp
        })
    },
    async delete({ commit }, id) {
      return await axios
        .delete(`timeoff-request/${id}/`)
        .then(resp => {
          commit('DELETE_DATA', id)
          return resp
        })
    },
  },
}
