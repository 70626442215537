import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios';
import {
  addRepresentationStrings,
  isNegetiveRepresentationString,
} from '@/utils/numbers.js';

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    calculateNetTotal({}, payslip) {
      return payslip.final_amount;
    },

    async calculatePayslip({}, data) {
      let errObject = null;
      let resultPromises = data.map(async (item) => {
        try {
          return await axios.post(`/payslip/${item.id}/calculate/`);
        } catch (err) {
          errObject = err;
        }
      });

      let results = await Promise.all(resultPromises);

      if (errObject) {
        throw errObject;
      }

      return results;
    },

    

    async calculatePayslipWithToast({ dispatch }, data) {
      await axios.exceptionHandler(async () => {
        await dispatch('calculatePayslip', data);
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Calculation Completed',
            variant: 'success',
            text: '',
            icon: 'DoneIcon',
          },
        });
      });
    },
    calculateGrossTotal({}, payslip) {
      let result = '0.00';
      result = addRepresentationStrings(result, payslip.final_amount);
      result = addRepresentationStrings(result, payslip.ss_deduction);
      let negetiveLinesSum = payslip.lines
        .filter((a) => isNegetiveRepresentationString(a.amount))
        .reduce((a, b) => addRepresentationStrings(a.amount, b.amount), {
          amount: '0.0',
        });
      if (typeof negetiveLinesSum === 'object') {
        negetiveLinesSum = negetiveLinesSum.amount;
      }
      negetiveLinesSum = negetiveLinesSum.replace('-', '');
      result = addRepresentationStrings(result, negetiveLinesSum);
      return result;
    },
  },
};
