import axios from '@axios'

export default {
    namespaced: true,
    state: {
        data: {

            results:[]
        },
        current: { names: [] },
        meta: {}
    },
    mutations: {
        SET_DATA(state, data){
            state.data = data
        },
        PUSH_DATA(state, data){
            state.data.results.push(resp.data)
        },
        UPDATE_DATA(state, data){
    
            state.data.results = state.data.results.map(it => it.id != id ? it : data)
        },
        DELETE_DATA(state, id){
            state.data.results = state.data.results.filter(it => it.id != id)
        },
    },
    getters: {
        names: (state) => state.current.names,
        own: (state) => {

            let obj = [
                { name: "first_name", translate: "First Name", arabic: {}, english: {}, type: "own" },
                { name: "second_name", translate: "Second Name", arabic: {}, english: {}, type: "own" },
                { name: "third_name", translate: "Third Name", arabic: {}, english: {}, type: "own" },
                { name: "last_name", translate: "Last Name", arabic: {}, english: {}, type: "own" },
            ]

            let own = state.current.names.filter(it => it.type == "own")

            own.forEach(it => {
                let nameObject = obj.find(i => i.name == it.name)
                if (nameObject) {
                    nameObject[it.language.name] = it
                }
            })
            return obj
        },
        mother: (state) => {

            let obj = [
                { name: "first_name", translate: "First Name", arabic: {}, english: {}, type: "mother" },
                { name: "second_name", translate: "Second Name", arabic: {}, english: {}, type: "mother" },
                { name: "third_name", translate: "Third Name", arabic: {}, english: {}, type: "mother" },
                { name: "last_name", translate: "Last Name", arabic: {}, english: {}, type: "mother" },
            ]

            let own = state.current.names.filter(it => it.type == "mother")

            own.forEach(it => {
                let ob = obj.find(i => i.name == it.name)
                if (ob) {
                    ob[it.language.name] = it
                }
            })
            return obj
        },
    },
    actions: {
        async getAll({commit},{ search, pagesize }) {
            return await axios
                .get(`employee/`)
                .then((resp) => {
                    commit("SET_DATA", resp.data)
                    return resp
                })
        },
        async getOne({ commit, state }, id) {
            return await axios
                .get(`employee/${id}/`)
                .then((resp) => {
                    state.current = resp.data
                    return resp
                })
        },
        async create({ commit }, fd) {
            return await axios
                .post(`employee/`, fd)
                .then((resp) => {
                    commit("PUSH_DATA", resp.data)
                    return resp
                })
        },
        async update({ commit }, {id, fd}) {
            return await axios
                .patch(`employee/${id}/`, fd)
                .then((resp) => {
                    return resp
                })
        },
        async delete({ commit }, id) {
            return await axios
                .delete(`employee/${id}/`)
                .then((resp) => {
                    return resp
                })
        }
    },
}