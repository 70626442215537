import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapAPIcalls } from "@/utils/api/common";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  getters: {},
  actions: {
    async createPayslips({}, data) {
      return mapAPIcalls(
        async (item) =>
          await axios.post(`/payslip-batch/${item.id}/create-payslips/`),
        data
      );
    },
    async createPayslipsWithToast({ dispatch }, data) {
      return await axios.exceptionHandler(async () => {
        let response = await dispatch("createPayslips", data);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Payslips Created",
            variant: "success",
            text: "",
            icon: "DoneIcon",
          },
        });
        return response;
      });
    },
  },
};
