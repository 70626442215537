import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
function loadLocaleMessages() {
  const locales = import.meta.globEager("./locales/*.json");
  const messages = {};
  for (const path in locales) {
    if (locales.hasOwnProperty(path)) {
      const locale = path.match(/([A-Za-z0-9-_]+)\.json$/i)[1];
      messages[locale] = locales[path];
    }
  }
  return messages;
}
export default new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
  silentTranslationWarn:
    import.meta.env.NODE_ENV === "development" ||
    import.meta.env.NODE_ENV === "production",
});
