import Vue from "vue";
import Vuex from "vuex";

import toastPlugin from "@/libs/toastification";

import actionList from "./base/actionList";
import app from "./base/app";
import appConfig from "./base/appConfig";
import verticalMenu from "./base/verticalMenu";

import APISchema from "./api/schema";
import CRUD from "./api/crud";
import auth from "./api/auth.js";
import export_data from "./api/export";

import user from "./modules/base/user";
import devices from "./modules/base/devices";
import attachment from "./modules/base/attachment";

import equipmentBrand from "./modules/equipment/equipment_brand";
import equipment from "./modules/equipment/equipments";
import jobs from "./modules/reqruitment/jobs";

import equipmentsHistory from "./modules/equipment/equipments_history";

import employe from "./modules/employee/employe";
import job_title from "./modules/employee/job_title";
import job_location from "./modules/employee/job_location";
import department from "./modules/employee/department";

import attendance from "./modules/attendance/attendance";
import userCheck from "./modules/attendance/userCheck";

import payslip from "./modules/payroll/payslip";
import payslipBatch from "./modules/payroll/payslip_batch";

import timeoffRole from "./modules/timeoff/roles";
import timeoffType from "./modules/timeoff/types";
import timeoffRequest from "./modules/timeoff/requests";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    actionList,
    app,
    appConfig,
    verticalMenu,

    APISchema,
    CRUD,
    auth,
    export: export_data,

    user,
    devices,
    attachment,

    equipmentBrand,
    equipment,
    attendance,
    equipmentsHistory,

    employe,
    job_title,
    job_location,
    department,

    timeoffRole,
    timeoffType,
    timeoffRequest,

    userCheck,

    payslip,
    payslipBatch,
    jobs,
  },
  // strict: import.meta.env.DEV,
});

toastPlugin(store);

export default store;
