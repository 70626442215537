import Vue from "vue";
import router from "@/router";
import axios from "axios";
import { gerOrCreateSignature } from "@/utils/localStorage";
import { handleAPIException } from "@/utils/api/exception_handlers";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL,
});

instance.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    config.headers.Signature = gerOrCreateSignature();
    if (localStorage.getItem("access_token")) {
      config.headers.Authorization = `JWT ${localStorage.getItem(
        "access_token"
      )}`;

      const user = jwtDecode(localStorage.getItem("access_token"));
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
      if (isExpired) {
        try {
          const response = await axios.post(
            `${import.meta.env.VITE_APP_BASE_URL}jwt-token/refresh/`,
            {
              refresh: localStorage.getItem("refresh_token"),
            }
          );

          const newTokens = response.data;
          const newAccessToken = newTokens.access;
          const newRefreshToken = newTokens.refresh;

          localStorage.setItem("access_token", newAccessToken);

          if (newRefreshToken) {
            localStorage.setItem("refresh_token", newRefreshToken);
          }

          config.headers.Authorization = `JWT ${newAccessToken}`;
        } catch (error) {
          router.push("/login");
        }
      }
    }

    return config;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

instance.exceptionHandler = async (callback) => {
  try {
    return await callback();
  } catch (err) {
    handleAPIException(err);
  }
};

const adjustDateByHours = (data, hours) => {
  if (typeof data === "object" && data !== null) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        if (typeof data[key] === "string" && !isNaN(Date.parse(data[key]))) {
          // Check if it's a date string and adjust by hours
          data[key] = dayjs(data[key]).subtract(hours, "hour").toISOString();
        } else if (typeof data[key] === "object") {
          // Recursively adjust dates in nested objects
          adjustDateByHours(data[key], hours);
        }
      }
    }
  }
  return data;
};

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // response.data = adjustDateByHours(response.data, 3);

    // Any status code that lies within the range of 2xx causes this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    handleAPIException(error);

    if (error.response == undefined) {
      console.log("Invalid error was passed to axios", error);
      return;
    }
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    const refreshToken = localStorage.getItem("refresh_token");
    if (refreshToken) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");

      return axios
        .post(`${import.meta.env.VITE_APP_BASE_URL}jwt-token/refresh/`, {
          refresh: refreshToken,
        })
        .then((response) => {
          const newTokens = response.data;
          const newAccessToken = newTokens.access;
          const newRefreshToken = newTokens.refresh;

          localStorage.setItem("access_token", newAccessToken);

          if (newRefreshToken) {
            localStorage.setItem("refresh_token", newRefreshToken);
          }

          error.config.headers.Authorization = `JWT ${newAccessToken}`;
        })
        .catch((error) => {
          // when fetching new access token failed
          if (!window.location.href.includes("/login")) router.push("/login");
        });
    } else {
      // when no refresh token found in the local storage
      if (!window.location.href.includes("/login")) router.push("/login");
    }

    return Promise.reject(error);
  }
);

// this add just for display json data
Vue.prototype.$http = instance;

export default instance;
