import $http from '@axios'


export default {
    namespaced: true,
    state: {
        all_data: [],
        createResponse: [],
    },
    getters: {
        all_data: state => state.all_data,
        createResponse: state => state.createResponse
    },
    actions: {
        async getOne({commit}, path) {
            return await $http.get(path);
        },
        async getList({ commit }, path) {
            const response = await $http.get(path)
            commit("SET_ITEMS", response.data)
            return response;
        },

        async create({ commit }, params) {
            const response = await $http.post(params.path, params.form)
            commit("CREATE", response.data);
            return response;
        },

        async update({ commit }, params) {
            const response = await $http.put(params.path, params.form)
            commit("UPDATE", response.data.data);
        },
        async patch({ commit }, params) {
            const response = await $http.patch(params.path, params.form)
            commit("PATCH", response.data.data);
            return response;
        },

        async delete({ commit }, path) {
            const response = await $http.delete(path)
            commit("DELETE", 'delete');
        },
        async clearCache({commit}){
            return commit
        }
    },
    mutations: {
        SET_ITEMS(state, data) {
            state.all_data = data
        },
        CREATE(state, data) {
            state.createResponse = data
        },
        UPDATE(state, data) {
            state.all_data.data = state.all_data.data.map(item => item = item.id == data.id ? data : item)
        },
        DELETE(state, id) {
            state.all_data
        },
        PATCH(state, id) {
            state.all_data 
        },
        CLEAR_CACHE(state){
            state.all_data = []
        }
        
    }
}