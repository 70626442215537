
<div
  id="app"
  class="h-100"
  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
  :class="[skinClasses, $i18n.locale == 'ar' ? 'rtl' : 'ltr']"
>
  <router-view />
  <scroll-to-top v-if="enableScrollToTop" />
</div>
