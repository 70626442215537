export async function mapAPIcalls(apiCallback, records){
    let errObject = null;
    let resultPromises = records.map(async (item) => {
      try {
        return await apiCallback(item);
      } catch (err) {
        errObject = err;
      }
    });

    let results = await Promise.all(resultPromises);

    if (errObject) {
      throw errObject;
    }
    return results;
}