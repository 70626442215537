export default {
  namespaced: true,
  state: {
    actionListState: null,
  },
  getters: {},
  mutations: {
    RESET_ACTION_LIST_STATE(state) {
      state.actionListState = null;
    },
  },
  actions: {
    resetActionListState({ commit }) {
      commit('RESET_ACTION_LIST_STATE');
    },
  },
};
