import axios from "@axios";

export default {
  namespaced: true,
  state: {
    errors: [],
  },
  mutations: {
    SET_ERRORS(state, obj) {
      state.errors = obj;
    },
  },
  getters: {},
  actions: {
    async export({ commit, state }, params) {
      state.errors = {};
      return await axios
        .post(`${params.url}/export/`, params.fd, { responseType: "blob" })
        .then((resp) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(resp.data);
          let date = new Date().toLocaleDateString();
          link.download = `${params.url}.${date}.${params.fd.format}`;
          link.click();
          state.errors = {};
        })
        .catch(({ error }) => {
          if (error) {
            if (error.response.status == 400) {
              error.response.data.text().then((value) => {
                state.errors = JSON.parse(value);
              });
            } else {
              state.errors = { message: error.response.statusText };
            }
          }
        });
    },
  },
};
