import moment from "moment-timezone";
import axios from "@axios";
import store from "@/store";

export const formatDate = (date, timeZone = "Asia/Baghdad") => {
  // Parse the date string correctly in UTC and then convert to the desired timezone
  return moment.utc(date).tz(timeZone).format("YYYY-MM-DD HH:mm");
};

export const hasPermissions = (requiredPermissions) => {
  const user = store.state.auth.user;

  // Check if user and user.permissions are defined
  if (user && user.permissions) {
    return requiredPermissions.every((permission) =>
      user.permissions.includes(permission)
    );
  } else {
    // Handle the case where user or user.permissions is undefined
    return false;
  }
};

export const getOrSetStore = (state, keyName, endpoint, commitCallback) => {
  return new Promise((resolve, reject) => {
    let currentObject = state[keyName];
    if (Object.keys(currentObject).length === 0) {
      axios
        .get(endpoint)
        .then((response) => {
          const data = response.data;
          commitCallback(data);
          resolve(data);
        })
        .catch(() => {
          if (endpoint.includes("is-checked-in")) {
            store.state.app.hasEmployeeRecord = false;
          }
          const data = {};
          commitCallback(data);
          resolve(data);
        });
    } else {
      resolve(currentObject);
    }
  });
};
